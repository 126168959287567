// import { notification } from 'antd';
// import axios from 'axios';

// export default function getApiClient() {
//   const api = axios.create({
//     baseURL: process.env.api
//   });

//   api.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');

//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   });
  

//   api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       if (error.response?.status === 401) {
//         localStorage.removeItem('token');
//        // notification.useNotification({stack:{threshold:1}})
//         notification.error({
//           message: `Tentativa de acesso`,
//           description: `Acesso expiradoo`,
//           duration: 3,
//           onClose: () => window.location.href = '/',
//           placement: 'topRight',

//         });
//         // window.location.href = '/';
//       }
//       return Promise.reject(error);
//     }
//   );

//   return api;
// }


import { notification } from 'antd';
import axios from 'axios';

export default function getApiClient() {
  const api = axios.create({
    baseURL: process.env.api
  });

  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  let notificationDisplayed = false;

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 && !notificationDisplayed) {
        notificationDisplayed = true;
        localStorage.removeItem('token');
        // notification.useNotification({stack:{threshold:1}})
        // notification.error({
        //   message: `Tentativa de acesso`,
        //   description: `Acesso expirado`,
        //   duration: 3,
        //   onClose: () => {
        //     notificationDisplayed = false;
        //     window.location.href = '/';
        //   },
        //   placement: 'topRight'

        // });
        // window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );

  return api;
}