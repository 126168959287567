'use client'
import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { MenuTheme, notification } from 'antd';
import { useRouter, usePathname } from 'next/navigation';
import { api } from '@/services/api';
import useIdleTimeout from '@/hooks/inatividade'

interface GrupoContextType {
  grupo: number | null;
  unidade: number | null;
}

interface GrupoSelectContextType {
  id: number;
  nome: string;
  unidades: UnidadeSelectContextType[];
}

interface UnidadeSelectContextType {
  id: number;
  nome: string;
}

interface PerfilType {
  status: any;
  usuario: any;
  perfil: any;
  acessos: any;
  empresas: GrupoSelectContextType[] | null;
}

interface AuthContextType {
  isAutenticated: boolean;
  redefinirSenha: boolean;
  perfil: PerfilType | undefined;
  themeRunning: MenuTheme;
  grupoContext: GrupoContextType;
  loading: boolean;
  handleGrupo: (value: number) => void;
  handleUnidade: (value: number) => void;
  login: (username: string, password: string, captcha: string) => void;
  recuperarSenha: (username: string) => void;
  handleLoading: (value: boolean) => void;
  logout: () => void;
  obterPermissaoFuncionalidades : (funcionalidade: any, paginaid: any ) => any;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [isAutenticated, setIsAutenticated] = useState(false);
  const [redefinirSenha, setRedefinirSenha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [themeRunning, setThemeRunning] = useState<MenuTheme>('dark');
  const [grupoContext, setGrupoContext] = useState<GrupoContextType>({ grupo: null, unidade: null });
  const [perfil, setPerfil] = useState<PerfilType | undefined>();



  const obterPermissaoFuncionalidades = (funcionalidade: any, paginaid: any ): any => {
    const acesso = perfil?.acessos.find((x: any) => x.menu.id == paginaid)
    if(!acesso )
      return false;
    return acesso[funcionalidade]

  }

  useIdleTimeout(() => {
    if (isAutenticated) {
      //logout();
      router.push('/');
    }
 }, 1800000); // 30 minutos de inatividade

  useEffect(() => {
    const fetchData = async () => {
      if (pathname !== '/') {
        const token = localStorage.getItem('token');
        if (token) {
          setIsAutenticated(true);
        }

        const storedPerfil = JSON.parse(localStorage.getItem('perfil') || "{}");
        setPerfil(storedPerfil);
      } else {
        setIsAutenticated(false);
        setPerfil(undefined);
      }
    };

    fetchData();
  }, [pathname]);

  const handleGrupo = (value: number) => {
    setGrupoContext({ ...grupoContext, grupo: value });
  };

  const handleUnidade = (value: number) => {
    if (value) {
      setGrupoContext({ ...grupoContext, unidade: value });
      handleLoading(true)
      router.push('/dashboard');
    } else {
      notification.error({
        message: 'Erro',
        description: 'Selecione uma Unidade de Négocio antes de prosseguir.',
      });
    }
  };

  const handleLoading = (value: boolean) => {
    setLoading(value);
  };

  const login = async (username: string, password: string, captcha: string) => {
    try {

      localStorage.removeItem('token');
      localStorage.removeItem('perfil');

      const response = await api.post('https://api.hml.patrymon.com.br/Usuario/Login', {
        username,
        password,
        captcha,
      });

      if (response.data.success) {
        localStorage.setItem("token", response?.data?.data?.token?.accessToken);
        setIsAutenticated(true);

        const responsePerfil = await api.get(`https://api.hml.patrymon.com.br/Usuario/ObterPerfilUsuario?usuarioId=${response.data.data.userId}`);
        localStorage.setItem("perfil", JSON.stringify(responsePerfil?.data?.data));
        setPerfil(responsePerfil.data.data);

        if (response.data.data.status === 3) {
          setRedefinirSenha(true);
          router.push(`/recuperar-senha?email=${response.data.data.username}&token=${response.data.data.token.accessToken}`);
        }
      } else {
       if( response.data.messages[0] !='Erro ao validar o captcha.') 
        notification.error({
          message: `Tentativa de acesso`,
          description: response.data.messages[0],
          placement: 'topRight',
        });

        localStorage.removeItem("token");
      }
    } catch (error) {
      notification.error({
        message: `Erro ao fazer login`,
        description:  `Erro ao fazer login`,
        placement: 'topRight',
      });
      localStorage.removeItem("token");
    }
  };

  const recuperarSenha = async (username: string) => {
    try {
      const response = await api.post('https://api.hml.patrymon.com.br/Usuario/RecuperarSenha', { username });
      if (response.data.success) {
        notification.success({ message: 'E-mail de recuperação de senha enviado com sucesso' });
      } else {
        notification.error({
          message: 'Tentativa de recuperação',
          description: response.data.messages[0],
          placement: 'topRight',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Falha na tentativa de recuperação',
        description: 'Erro ao recuperar a senha. Por favor, tente novamente.',
        placement: 'topRight',
      });
    }
  };

  const logout = () => {
    setIsAutenticated(false);
    router.push('/'); 
    localStorage.removeItem("perfil");
    localStorage.removeItem("token");
    localStorage.removeItem("_grecaptcha");
   
};
  const authContextValue: AuthContextType = {
    isAutenticated,
    redefinirSenha,
    perfil,
    themeRunning,
    grupoContext,
    loading,
    handleGrupo,
    handleUnidade,
    handleLoading,
    login,
    recuperarSenha,
    logout,
    obterPermissaoFuncionalidades
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser utilizado dentro de um AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
export type { AuthContextType };


// 'use client'
// import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
// import { MenuTheme, notification } from 'antd';
// import { useRouter } from 'next/navigation';
// import { api } from '@/services/api';

// interface GrupoContextType {
//   grupo: number | null;
//   unidade: number | null;
// }

// interface GrupoSelectContextType {
//   id: number;
//   nome: string;
//   unidades: UnidadeSelectContextType[];
// }

// interface UnidadeSelectContextType {
//   id: number;
//   nome: string;
// }

// interface PerfilType {
//   status: any;
//   usuario: any;
//   perfil: any;
//   acessos: any;
//   empresas: GrupoSelectContextType[] | null;
// }

// interface AuthContextType {
//   isAutenticated: boolean;
//   redefinirSenha: boolean;
//   perfil: PerfilType | undefined;
//   themeRunning: MenuTheme;
//   grupoContext: GrupoContextType;
//   loading: boolean;
//   handleGrupo: (value: number) => void;
//   handleUnidade: (value: number) => void;
//   login: (username: string, password: string, captcha: string) => void;
//   recuperarSenha: (username: string) => void;
//   handleLoading: (value: boolean) => void;
//   logout: () => void;
// }

// const AuthContext = createContext<AuthContextType | undefined>(undefined);

// interface AuthProviderProps {
//   children: ReactNode;
// }

// const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const router = useRouter();
//   const [isAutenticated, setIsAutenticated] = useState(false);
//   const [redefinirSenha, setRedefinirSenha] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [themeRunning, setThemeRunning] = useState<MenuTheme>('dark');
//   const [grupoContext, setGrupoContext] = useState<GrupoContextType>({ grupo: null, unidade: null });
//   const [perfil, setPerfil] = useState<PerfilType | undefined>();

//   useEffect(() => {
//     const fetchData = async () => {
//       const token = localStorage.getItem('token');
//       if (token) {
//         setIsAutenticated(true);
//       }

//       const storedPerfil = JSON.parse(localStorage.getItem('perfil') || "{}");
//       setPerfil(storedPerfil);
//     };

//     fetchData();
//   }, []);

//   const handleGrupo = (value: number) => {
//     setGrupoContext({ ...grupoContext, grupo: value });
//   };

//   const handleUnidade = (value: number) => {
//     if (value) {
//       setGrupoContext({ ...grupoContext, unidade: value });
//       handleLoading (true)
//       router.push('/dashboard');
//     } else {
//       notification.error({
//         message: 'Erro',
//         description: 'Selecione uma Unidade de Négocio antes de prosseguir.',
//       });
//     }
//   };

//   const handleLoading = (value: boolean) => {
//     setLoading(value);
//   };
  
//   const login = async (username: string, password: string, captcha: string) => {
//     try {
//       const response = await api.post('https://api.hml.patrymon.com.br/Usuario/Login', {
//         username,
//         password,
//         captcha,
//       });

//       if (response.data.success) {
//         localStorage.setItem("token", response?.data?.data?.token?.accessToken);
//         setIsAutenticated(true);

//         const responsePerfil = await api.get(`https://api.hml.patrymon.com.br/Usuario/ObterPerfilUsuario?usuarioId=${response.data.data.userId}`);
//         localStorage.setItem("perfil", JSON.stringify(responsePerfil?.data?.data));
//         setPerfil(responsePerfil.data.data);

//         if (response.data.data.status === 3) {
//           setRedefinirSenha(true);
//           router.push(`/recuperar-senha?email=${response.data.data.username}&token=${response.data.data.token.accessToken}`);
//         }
//       } else {
//         notification.error({
//           message: `Tentativa de acesso`,
//           description: response.data.messages[0],
//           placement: 'topRight',
//         });

//         localStorage.removeItem("token");
//       }
//     } catch (error) {
//       console.error('Erro ao fazer login:', error);
//       setIsAutenticated(false);
//       localStorage.removeItem("token");
//     }
//   };

//   const recuperarSenha = async (username: string) => {
//     try {
//       const response = await api.post('https://api.hml.patrymon.com.br/Usuario/RecuperarSenha', { username });
//       if (response.data.success) {
//         notification.success({ message: 'E-mail de recuperação de senha enviado com sucesso' });
//       } else {
//         notification.error({
//           message: 'Tentativa de recuperação',
//           description: response.data.messages[0],
//           placement: 'topRight',
//         });
//       }
//     } catch (error) {
//       notification.error({
//         message: 'Falha na tentativa de recuperação',
//         description: 'Erro ao recuperar a senha. Por favor, tente novamente.',
//         placement: 'topRight',
//       });
//     }
//   };

//   const logout = () => {
//     setIsAutenticated(false);
//     localStorage.removeItem("perfil");
//     localStorage.removeItem("token");
//     localStorage.removeItem("_grecaptcha");
//     router.push('/');
//     router.refresh(); 
//   };

//   const authContextValue: AuthContextType = {
//     isAutenticated,
//     redefinirSenha,
//     perfil,
//     themeRunning,
//     grupoContext,
//     loading,
//     handleGrupo,
//     handleUnidade,
//     handleLoading,
//     login,
//     recuperarSenha,
//     logout,
   
//   };

//   return (
//     <AuthContext.Provider value={authContextValue}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// const useAuth = (): AuthContextType => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth deve ser utilizado dentro de um AuthProvider');
//   }
//   return context;
// };

// export { AuthProvider, useAuth };
// export type { AuthContextType };
