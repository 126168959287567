import { useEffect, useRef } from 'react';
import { notification } from 'antd';
import { useRouter } from 'next/navigation';

const useIdleTimeout = (onTimeout: () => void, timeout: number = 1800000) => { // 30 minutos em milissegundos
  const router = useRouter();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        notification.warning({
          message: 'Inatividade detectada',
          description: 'Você será redirecionado para a página inicial devido à inatividade.',
        });
        onTimeout();
      }, timeout);
    };

    const handleActivity = () => resetTimeout();

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimeout();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [onTimeout, timeout]);

  return null;
};

export default useIdleTimeout;
